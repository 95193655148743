import * as Style from "./styles";
import * as StyleGlobal from '../../shared/GlobalStyles/styles'
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getToken, logout } from "../../services/auth";
import IconFreight from '../../assets/menuLateral/IconFreight.svg';
import { useCountTable } from "../../hooks/useCountTable";
import { useCountDelivery } from "../../hooks/useCountDelivery";
import { theme } from "../../shared/GlobalStyles/theme";
import { Navigation } from "../Navigation";
import { Fragment } from "react";
import { handleClickCloseModalGeneric, verifyMouse } from "../../functionsGlobal";

export const SideBar = ({socket}) => {
    const history = useHistory();
    const {countTable} = useCountTable({socket});
    const {coutDelivery} = useCountDelivery({socket});
    const [openSidebar, setopenSideBar] = useState(true);
    const colorSvg = JSON.parse(getToken('SISTESHOP'));
    const sideBar = JSON.parse(getToken('SIDEBAR'));
    const [itemsSideBar, setItemsSideBar] = useState([
        {id:'1',  disableLink:false,title: 'Gerenciamento e vendas', isVisible:true},
        {id:'2',  disableLink:false,link: () => routers('/dashboard'), name: 'Início',  isVisible: true, icon: <Style.IconHome stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/> },
        {id:'3',  disableLink:false,link: () => routers('/Carregarmesas'), name: 'Mesas', isVisible: colorSvg ? false : true, icon: <Style.IconTable
            stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}
            fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }
        /> },
        {id:'4',  disableLink:false,link: () => routers('/Delivery'), name: 'Delivery',  isVisible: true, icon: <Style.IconDelivery stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/> },
        {id:'19',  disableLink:false,link: () => routers('/Televendas'), name: 'Televendas',  isVisible: true, icon: <StyleGlobal.IconTeleSales fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/> },
        {id:'5',  disableLink:false,link: () => routers('/AdicionarProdutoVenda'), name: 'Venda', isVisible: true,  icon: <Style.IconConsultSales fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/> },
        {id:'6', disableLink:false, name: 'DF-e',  isVisible: true, icon: <Style.IconDfe fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>, isOPen: false, 
        subMenu:true,
        items:[
            {id:'6.1',  disableLink:false,link: () => routers('/AdicionarProdutoNFCE'), name: 'NFC-e',  isVisible: true, icon: <Style.IconConsultSales fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/> },
            {id:'6.2', disableLink:false, link: () => routers('/AdicionarProdutoEntradaNFE'), name: 'NF-e Entrada',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds. blue4}/>},
            // {
                // id:'6.2', disableLink:false, name: 'NF-e Entrada', isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds. blue4}/>,  isOPen: false, 
                // subMenu:true,
                // items:[
                //     {id:'6.2.3', disableLink:false, link: () => routers('/AdicionarProdutoEntradaNFEDevolucao'), name: 'Devolução',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},
                //     {id:'6.2.4', disableLink:false, link: () => routers('/AdicionarProdutoEntradaNFEBonificacao'), name: 'Bonificação',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},
                //     {id:'6.2.5', disableLink:false, link: () => routers('/AdicionarProdutoEntradaNFEAvariaPerca'), name: 'Avaria/Perca',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},
                //     {id:'6.2.6', disableLink:false, link: () => routers('/AdicionarProdutoEntradaNFEOutros'), name: 'Outros',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},
                // ]
            // },

            {id:'6.7', disableLink:false, link: () => routers('/AdicionarProdutoSaidaNFE'), name: 'NF-e Saída',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},

            // {
            //     id:'6.7', disableLink:false, name: 'NF-e Saída',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>,
            //     subMenu:true,
            //     items:[
            //         {id:'6.7.2', disableLink:false, link: () => routers('/AdicionarProdutoSaidaNFEDevolucao'), name: 'Devolução',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},
            //         {id:'6.7.3', disableLink:false, link: () => routers('/AdicionarProdutoSaidaNFEBonificacao'), name: 'Bonificação',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},
            //         {id:'6.7.4', disableLink:false, link: () => routers('/AdicionarProdutoSaidaNFEAvariaPerca'), name: 'Avaria/Perca',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},
            //         {id:'6.7.5', disableLink:false, link: () => routers('/AdicionarProdutoSaidaNFEOutros'), name: 'Outros',  isVisible: true, icon:<Style.IconNfe  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>},
            //     ]
            // },
        ]
        },

        {id:'7',  disableLink:false,link: () => routers('/listarVendas'), name: 'Consultar Vendas',  isVisible: true, icon: <StyleGlobal.IconOrder fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/> },
        {id:'8',  disableLink:false,link: () => routers('/AdicionarProdutoEntrada'), name: 'Entrada de Nota',  isVisible: true, icon: <Style.IconNoteEntry  fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/> },
        {id:'9',  disableLink:false,link: () => routers('/listarNotasEntrada'), name: 'Consultar Entrada',  isVisible: true, icon: <Style.IconConsultNoteEntry fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/> }, 
        {id:'10', disableLink:false, title: 'Configurações da Loja', isVisible:true},
        {id:'11', disableLink:false, name: 'Itens',  isVisible: true, icon: <Style.IconItems fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>, isOPen: false, 
        subMenu:true,
        items:[
            {id:'11.1', disableLink:false, link: () => routers('/listarProduto'), name: 'Itens', isVisible: true, icon: <Style.IconItems fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'11.2', disableLink:false, link: () => routers('/listarComplemento'), name: 'Complementos', isVisible: true, icon: <Style.IconComplement fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/> },
            {id:'11.3', disableLink:false, link: () => routers('/listarGrupoComplemento'), name: 'Grupo de complementos', isVisible: true, icon: <StyleGlobal.IconGroupComplement fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4} />},
            {id:'11.4', disableLink:false, link: () => routers('/listarFabricante'), name: 'Fabricante', isVisible: true, icon: <Style.IconPerson fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'11.5', disableLink:false, link: () => routers('/listarSecao'), name: 'Seção', isVisible: true, icon: <StyleGlobal.IconSection fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'11.6', disableLink:false, link: () => routers('/listarNcm'), name: 'NCM', isVisible: true, icon: <StyleGlobal.IconNcm fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'11.7', disableLink:false, link: () => routers('/listarCfop'), name: 'CFOP', isVisible: true, icon: <StyleGlobal.IconCfop fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>}
        ]
        },
        {id:'12', disableLink:false, name: 'Estoque',  isVisible: true, icon: <Style.IconStock fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>, isOPen: false, 
        subMenu:true,
        items:[
            {id:'12.1', disableLink:false, link: () => routers('/NotaAvulsa'), name: 'Entrada / Saída Avulsa', isVisible: true, icon: <Style.IconNoteSingle fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'12.2', disableLink:false, link: () => routers('/Inventario'), name: 'Inventário', isVisible: true, icon: <Style.IconInventory fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/> }]
        },
        {id:'13', disableLink:false, name: 'Pessoas',  isVisible: true, icon:<Style.IconPerson fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>, isOPen: false, 
        subMenu:true,
        items:[
            {id:'13.1', disableLink:false, link: () => routers('/listarCliente'), name: 'Clientes', isVisible: true, icon:<Style.IconPerson fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'13.2', disableLink:false, link: () => routers('/carregarAtendente'), name: 'Atendentes', isVisible: true, icon: <Style.IconAttendant 
                fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}
                stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}
            /> },
            {id:'13.3', disableLink:false, link: () => routers('/listarEntregador'), name: 'Entregador', isVisible: true, icon: <Style.IconDeliveryMan fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4} />  },
            {id:'13.4', disableLink:false, link: () => routers('/listarUsuario'), name: 'Usuários', isVisible: true, icon:  <Style.IconUser 
                fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}
                stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}
            />},
        ]},
        {id:'14', disableLink:false, name: 'Financeiro',  isVisible: true, icon: <StyleGlobal.IconPayment fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>, isOPen: false, 
        subMenu:true,
        items:[
            {id: '14.1', disableLink:false, link: () => routers('/listarFormaPagamento'), name: 'Forma de Pagamento', isVisible: true, icon: <StyleGlobal.IconCard fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'14.2', disableLink:false, link: () => routers('/listarReceita'), name: 'Receita', isVisible: true, icon: <StyleGlobal.IconGraphUp fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'14.3', disableLink:false, link: () => routers('/listarDespesa'), name: 'Despesa', isVisible: true, icon: <StyleGlobal.IconGraphDown fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'14.4', disableLink:false, link: () => routers('/listarContaCorrente'), name: 'Conta Corrente', isVisible: true, icon: <StyleGlobal.IconCurrentAccount fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'14.5', disableLink:false, link: () => routers('/listarContasPagar'), name: 'Contas a Pagar', isVisible: true, icon: <StyleGlobal.IconBillsToPay fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
            {id:'14.6', disableLink:false, link: () => routers('/listarContasReceber'), name: 'Contas a Receber', isVisible: true, icon: <StyleGlobal.IconBillsToReceive fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>},
        ]},
        {id:'15', disableLink:false, link: () => routers('/listarFrete'), name: 'Frete', isVisible: true, icon: <img src={IconFreight} alt='ícone de frete'/> },
        {id:'16', disableLink:false, title: 'Configurações', isVisible:true},
        {id:'17', disableLink:false, link: () => routers('/parametro'), name: 'Configurações', isVisible: true, icon: <Style.IconSettings fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/> },
        {id:'18', disableLink:false, link: () => routers('/QrCode'), name: 'App', isVisible: true, icon:<Style.IconApp fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>}

    ])

    const routers = (value) => {
        history.push(value); 
        if(window.innerWidth <= 780 && openSidebar) setopenSideBar(false);
    }   

    const logoutSystem = () => {
        logout('ID_USUARIO');
        logout('CNPJ');
        logout('LOGIN');
        logout('NOMEEMPRESA');
        logout('DIAS');
        logout('ADM');
        logout('RULESPERMISSIONUSER');
        logout('SENDEMAIL');
        logout('COMPANIES');
        logout('SIDEBAR');
        logout('ID_VENDEDOR');
        history.push('/')
    }

    useEffect(() => {
        verifyOnRizise();
        verifyMediaQueryWidth();
        return () => {
            window.removeEventListener('resize', verifyWidth);
        }
    },[])

    useEffect(() => {
        isVisibleSideBar()
    }, [])

    const isVisibleSideBar = () => {
        if(sideBar){
            Object.entries(sideBar).map(item => {
                itemsSideBar.map(sidebar => {
                    if(sidebar?.title) sidebar.isVisible = false;
                    if(sidebar?.name && (sidebar.name.toUpperCase() !== item[0].toUpperCase() && sidebar.name.toUpperCase() !== 'PESSOAS')) sidebar.isVisible = false
                
                    if(sidebar?.items?.length > 0){
                        sidebar.items.map(items => {
                            if(items.name.toUpperCase() !== 'CLIENTES' && items.name.toUpperCase() !== 'USUÁRIOS') items.isVisible = false
                        })
                    }

                })
            })
            setItemsSideBar([...itemsSideBar])   
        }

    }

    const verifyMediaQueryWidth = () => {
        if(window.innerWidth <= 1280){
            if (openSidebar)setopenSideBar(false);
        }
    }

    const verifyWidth = () => {
        if(window.innerWidth <= 1280) setopenSideBar(false);
        else setopenSideBar(true);
    }

    const verifyOnRizise = () => {
        window.addEventListener('resize', verifyWidth)
    } 

    const handleClickArrow = (item) => {
        let {isOPen, id} = item;

        isOPen = isOPen ? false : true;
            itemsSideBar.map((item) => {
                if(item.id === id) item.isOPen =  isOPen;
                else {
                    if(item.items !== undefined){
                        
                        item.items.map(ele => {
                            if(ele.id === id) ele.isOPen =  !ele.isOPen;
                            return ele;
                        })
                    }
                }
                return item;
            })
 
        setItemsSideBar([...itemsSideBar]);
    }

    const verifyIfMouseEnterInNavigationRoot = () => {
        if(window.innerWidth <= 1280){
            if(!openSidebar) setopenSideBar(true);
        }
    }    

    const verifyIfMouseLeaveInNavigationRoot = () => { 
        if(window.innerWidth <= 1280 && window.innerWidth > 768){
            if(openSidebar) setopenSideBar(false);
        }
    }
    
    return(
        <>
            <Style.IconCloseSidBar onClick={() => setopenSideBar(!openSidebar)}>
                <Style.IconCloseSidBarSvg stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4 }/>
            </Style.IconCloseSidBar>

            <Style.ContainerSidabar openSidebar={openSidebar} id={'modal-generic'}
                    onMouseUp={(event) => verifyMouse(event, handleClickCloseModalGeneric, () => setopenSideBar(false))}
                    onMouseDown={(event) => verifyMouse(event, handleClickCloseModalGeneric, () => setopenSideBar(false))}
            >
                <Navigation.Root openSidebar={openSidebar}  onMouseEnter={verifyIfMouseEnterInNavigationRoot}
                onMouseLeave={verifyIfMouseLeaveInNavigationRoot}
                >
                    <Navigation.ListItem  >
                        {
                            itemsSideBar.length && 
                                itemsSideBar.map((item) => {
                                    if(item.title && item.isVisible) return <Navigation.ListItemTitle key={item.id} text={item.title} openSidebar={openSidebar}/>
                                    
                                    return (
                                        <Fragment key={item.id}>
                                            {
                                                item.subMenu && !openSidebar ? (
                                                    null
                                                )
                                                :(
                                                    item.isVisible? (
                                                        <Navigation.ListItemButton 
                                                            nameTooltip={item.name}
                                                            openSidebar={openSidebar}
                                                            onClick={() => {
                                                                if(item.link !== undefined && !item.disableLink) item.link();
                                                                if(item.subMenu) handleClickArrow(item);
                                                                }
                                                            }
                                                        >
                                                            <Navigation.ListItemIcon>
                                                                {item.icon}
                                                            </Navigation.ListItemIcon>
                                                            <Navigation.ListItemText text={item.name} openSidebar={openSidebar}/>
                                                            {
                                                                item.items !== undefined && openSidebar &&
                                                                <Navigation.ListItemIcon openSidebar={openSidebar}>
                                                                    {
                                                                        item.isOPen ? (
                                                                            <StyleGlobal.IconArrowUp stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>
                                                                        )
                                                                        :(

                                                                        <StyleGlobal.IconArrowDown stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>
                                                                        )
                                                                    }
                                                                </Navigation.ListItemIcon>
                                                            }

                                                            {
                                                                item.name === 'Delivery' && 
                                                                <Style.BoxQtdOrder openSidebar={openSidebar} color={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}>
                                                                    <p>{coutDelivery}</p>
                                                                </Style.BoxQtdOrder>
                                                            }
                                                            {
                                                                item.name === 'Mesas' &&
                                                                <Style.BoxQtdOrder openSidebar={openSidebar} color={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}>
                                                                    <p>{countTable}</p>
                                                                </Style.BoxQtdOrder>

                                                            }
                                                        </Navigation.ListItemButton> 

                                                    )
                                                    :(
                                                        null
                                                    )
                                                )
                                            }

                                            {
                                                !openSidebar? (
                                                    <Navigation.ListItem >
                                                        { 
                                                            item.items && item.items.map((ele, index) =>
                                                                ele?.items && ele?.items.length > 0 ?  (
                                                                    <Navigation.ListItem key={ele.id}>
                                                                        {
                                                                            ele.items.length > 0 && ele.items.map(item => 
                                                                                <Navigation.ListItemButton key={item.id}
                                                                                        nameTooltip={item.name}
                                                                                        padding={openSidebar ? '10px 16px 10px 96px' : null}
                                                                                        onClick={() => {
                                                                                            if(item.link !== undefined && !item.disableLink) item.link();
                                                                                            if(item.subMenu) handleClickArrow(item.items[index]);
                                                                                            }
                                                                                        }
                                                                                    >

                                                                                        <Navigation.ListItemIcon>
                                                                                            {item.icon}
                                                                                        </Navigation.ListItemIcon>
                                                                                        <Navigation.ListItemText text={item.name} openSidebar={openSidebar}/>

                                                                                        <Navigation.ListItemIcon openSidebar={openSidebar}>
                                                                                    
                                                                                        </Navigation.ListItemIcon>

                                                                                    </Navigation.ListItemButton>

                                                                        
                                                                                )
                                                                        }

                                                                    </Navigation.ListItem>
        
                                                                )
                                                                :(
                                                                    ele.isVisible &&
                                                                    <Navigation.ListItemButton key={ele.id}
                                                                        nameTooltip={ele.name}
                                                                        padding={openSidebar ? '10px 16px 10px 48px' : null}
                                                                        openSidebar={openSidebar} onClick={() => {
                                                                            if(!ele.disableLink) ele.link();
                                                                        }}
                                                                    >
                                                      
                                                   
                                                                        <Navigation.ListItemIcon>
                                                                            {ele.icon}
                                                                        </Navigation.ListItemIcon>
                                                                        <Navigation.ListItemText text={ele.name} openSidebar={openSidebar}/>
                                                                    </Navigation.ListItemButton>

                                                                )
                                                            )
                                                        }
                
                                                    </Navigation.ListItem>  
                                                )
                                                : (
                                                    item.isOPen? (
                                                        <Navigation.ListItem >
                                                            { 
                                                                item.items && item.items.map((ele, index) =>
                                                                    ele?.items && ele?.items.length > 0 ? (
                                                                        <Fragment key={ele.id}>
                                                                            <Navigation.ListItemButton 
                                                                                nameTooltip={ele.name}
                                                                                padding={openSidebar ? '10px 16px 10px 48px' : null}
                                                                                onClick={() => {
                                                                                    if(ele.link !== undefined && !ele.disableLink) ele.link();
                                                                                    if(ele.subMenu) handleClickArrow(item.items[index]);
                                                                                    }
                                                                                }
                                                                            >

                                                                                <Navigation.ListItemIcon>
                                                                                    {ele.icon}
                                                                                </Navigation.ListItemIcon>
                                                                                <Navigation.ListItemText text={ele.name} openSidebar={openSidebar}/>

                                                                                <Navigation.ListItemIcon openSidebar={openSidebar}>
                                                                                {
                                                                                    ele.isOPen ? (
                                                                                        <StyleGlobal.IconArrowUp stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>
                                                                                    )
                                                                                    :(

                                                                                    <StyleGlobal.IconArrowDown stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}/>
                                                                                    )
                                                                                }
                                                                                </Navigation.ListItemIcon>

                                                                            </Navigation.ListItemButton>


                                                                            {

                                                                                ele.isOPen && <Navigation.ListItem>
                                                                                    {
                                                                                        ele.isOPen && ele.items.length > 0 && ele.items.map(item => 
                                                                                            <Navigation.ListItemButton key={item.id}
                                                                                                    nameTooltip={item.name}
                                                                                                    padding={openSidebar ? '10px 16px 10px 96px' : null}
                                                                                                    onClick={() => {
                                                                                                        if(item.link !== undefined && !item.disableLink) item.link();
                                                                                                        if(item.subMenu) handleClickArrow(item.items[index]);
                                                                                                        }
                                                                                                    }
                                                                                                >
        
                                                                                                    <Navigation.ListItemIcon>
                                                                                                        {item.icon}
                                                                                                    </Navigation.ListItemIcon>
                                                                                                    <Navigation.ListItemText text={item.name} openSidebar={openSidebar}/>
        
                                                                                                    <Navigation.ListItemIcon openSidebar={openSidebar}>
                                                                                             
                                                                                                    </Navigation.ListItemIcon>
        
                                                                                                </Navigation.ListItemButton>
    
                                                                                 
                                                                                            )
                                                                                    }

                                                                                </Navigation.ListItem>
                
                                                                            }
                                                                        </Fragment>
                                                                    )
                                                                    :(
                                                                        ele.isVisible &&
                                                                        <Navigation.ListItemButton key={ele.id}
                                                                            nameTooltip={ele.name}
                                                                            padding={openSidebar ? '10px 16px 10px 48px' : null}
                                                                            openSidebar={openSidebar} onClick={() => {
                                                                                if(!ele.disableLink) ele.link();
                                                                            }}
                                                                        >
                                                                            <Navigation.ListItemIcon>
                                                                                {ele.icon}
                                                                            </Navigation.ListItemIcon>
                                                                            <Navigation.ListItemText text={ele.name} openSidebar={openSidebar}/>
                                                                        </Navigation.ListItemButton>

                                                                    )
                                                                )
                                                            }
                                                        </Navigation.ListItem>  
                                                    ): null    
                                                )
                                            }
                                        </Fragment>
                                    )
                                })
                        }
                    </Navigation.ListItem>
                    <Style.BoxFooter openSidebar={openSidebar} onClick={logoutSystem}>
                        <Style.IconLogout 
                            fill={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}
                            stroke={colorSvg ? theme.backgrounds.blue : theme.backgrounds.blue4}
                        />
                        { openSidebar && <p>Sair</p>}
                    </Style.BoxFooter>
                </Navigation.Root>
            </Style.ContainerSidabar>

        </>
    )

}